import React from 'react';
import { Create, SimpleForm } from "react-admin";
import CreateActions from "../../components/actionButtons/CreateActions";
import CreateToolbar from "../../components/toolbars/CreateToolbar/CreateToolbar";
import CourtForm from "../../components/forms/CourtForm";

const CourtCreate = () => (
  <Create actions={<CreateActions/>} redirect={false}>
    <SimpleForm toolbar={<CreateToolbar/>} component={CourtForm}/>
  </Create>
);

export default CourtCreate;
