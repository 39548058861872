const CAMERA_FIELDS = {
    id: {
        source: "id",
        label: "Id",
        // title: "Username",
    },
    username: {
        source: "username",
        label: "Username",
        // title: "Username",
    },
    password: {
        source: "password",
        label: "Password",
        // title: "Username",
    },
    courtId: {
        source: "courtId",
        label: "Court",
        // title: "Username",
        reference: "courts"
    },
    serialNumber: {
        source: "serialNumber",
        label: "Serial Number",
        // title: "serialNumber",
    },
    modelDescription: {
        source: "modelDescription",
        label: "Model Description",
        // title: "serialNumber",
    },
    rtspPort: {
        source: "rtspPort",
        label: "Rtsp Port",
        // title: "serialNumber",
    },
    apiPort: {
        source: "apiPort",
        label: "Api Port",
        // title: "serialNumber",
    },
    hasLineIn: {
        source: "hasLineIn",
        label: "Has Line In",
        // title: "serialNumber",
    },
    hasMic: {
        source: "hasMic",
        label: "Has Mic",
        // title: "serialNumber",
    },
    type: {
        source: "type",
        label: "Type",
        // title: "serialNumber",
    },
    role: {
        source: "role",
        label: "Role",
        // title: "serialNumber",
    },
};

export default CAMERA_FIELDS;