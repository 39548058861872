import {stringifyUrl} from "query-string";
import {ADMIN_API_BASE_URL} from "../utils/constants/url";
import httpClient from "../utils/helpers/httpClient";
import {parseCreateClub} from "../utils/helpers/parseClub";

export const livestreamSettingsProvider = {
    getList: (resource, params) => {
        const urlWithQueryParams = stringifyUrl({
            url: `${ADMIN_API_BASE_URL}/streaming-settings`,
            query: { query: params.filter.q, courtId: params.filter.courtId },
        });

        return httpClient(urlWithQueryParams).then(({ json: cameras }) => ({
            data: cameras,
            total: cameras?.length,
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${ADMIN_API_BASE_URL}/streaming-settings/${params.id}`).then(
            ({ json }) => ({
                data: json,
            }),
        ),

    getMany: (resource, params) => {
        const urlWithQueryParams = stringifyUrl({
            url: `${ADMIN_API_BASE_URL}/streaming-settings`,
        });

        return httpClient(urlWithQueryParams).then(({ json: cameras }) => ({
            data: cameras,
            total: cameras.length,
        }));
    },

    create: (resource, params) =>
        httpClient(`${ADMIN_API_BASE_URL}/streaming-settings`, {
            method: "POST",
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            return {data: json}
        }),

    update: (resource, params) =>
        httpClient(`${ADMIN_API_BASE_URL}/streaming-settings/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    delete: (resource, params) =>
        httpClient(`${ADMIN_API_BASE_URL}/streaming-settings/${params.id}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: json })),
}