import EditActions from "../../components/actionButtons/EditActions";
import {Edit, SimpleForm} from "react-admin";
import EditToolbar from "../../components/toolbars/EditToolbar/EditToolbar";
import React from "react";
import CameraForm from "../../components/forms/CameraForm";

const CameraEdit = () => (
    <Edit actions={<EditActions />} redirect={false}>
        <SimpleForm toolbar={<EditToolbar />}>
            <CameraForm edit />
        </SimpleForm>
    </Edit>
);

export default CameraEdit