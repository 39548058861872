import ClubListFiltersSearch from "../ClubList/ClubListFiltersSearch";
import ListActions from "../../../components/actionButtons/ListActions";
import React from "react";
import CAMERA_FIELDS from "../../../utils/constants/labels/CameraFields";
import {Datagrid, List, TextField} from "react-admin";

const CameraList = () => (
    <List
        filters={ClubListFiltersSearch()}
        pagination={false}
        actions={<ListActions />}
    >
        <Datagrid rowClick="show">
            <TextField
                source={CAMERA_FIELDS.id.source}
                label={CAMERA_FIELDS.id.label}
                emptyText="-"
            />
            <TextField
                source={CAMERA_FIELDS.serialNumber.source}
                label={CAMERA_FIELDS.serialNumber.label}
                emptyText="-"
            />
            <TextField
                source={CAMERA_FIELDS.modelDescription.source}
                label={CAMERA_FIELDS.modelDescription.label}
                emptyText="-"
            />
            <TextField
                source={CAMERA_FIELDS.type.source}
                label={CAMERA_FIELDS.type.label}
                emptyText="-"
            />
            <TextField
                source={CAMERA_FIELDS.role.source}
                label={CAMERA_FIELDS.role.label}
                emptyText="-"
            />
        </Datagrid>
    </List>
)

export default CameraList;