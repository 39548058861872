import React from "react";
import {
    TextInput,
    ReferenceArrayInput,
    AutocompleteArrayInput, required,
} from "react-admin";
import COURT_FIELDS from "../../utils/constants/labels/courtFields";
import ContainerPadding from "../layout/containerPadding/ContainerPadding";

const CourtForm = () => {

  return (
      <ContainerPadding padding="1% 2% 1% 2%">
          <TextInput
              source={COURT_FIELDS.name.source}
              label={COURT_FIELDS.name.label}
              fullWidth
              required
          />
          <TextInput
              source={COURT_FIELDS.description.source}
              label={COURT_FIELDS.description.label}
              fullWidth
          />
          <TextInput
              source={COURT_FIELDS.courtConfigurationId.source}
              label={COURT_FIELDS.courtConfigurationId.label}
              fullWidth
          />
          <TextInput
              source={COURT_FIELDS.address.source}
              label={COURT_FIELDS.address.label}
              fullWidth
          />
          <TextInput
              source={COURT_FIELDS.publicIp.source}
              label={COURT_FIELDS.publicIp.label}
              fullWidth
          />
          <TextInput
              source={COURT_FIELDS.networkHardwareInstalled.source}
              label={COURT_FIELDS.networkHardwareInstalled.label}
              fullWidth
          />
          <ReferenceArrayInput source="orgIds" reference="clubs" label='Orgs'>
            <AutocompleteArrayInput
                fullWidth
                label="Orgs"
                optionText={(record) => (
                    <span>
                <b>{record?.name}</b>
              </span>
                )}
                inputText={(record) => record?.name}
                validate={required()}
            />
          </ReferenceArrayInput>
      </ContainerPadding>
  );
};

export default CourtForm;