import {useRecordContext} from "react-admin";

const RecordReader = params => {
    const {stateSetter, valueToCheck} = params
    const record = useRecordContext()

    stateSetter(!!record?.[valueToCheck]?.id)

    return null
}

export default RecordReader