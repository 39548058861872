import {Create, SimpleForm} from "react-admin";
import CreateActions from "../../components/actionButtons/CreateActions";
import CreateToolbar from "../../components/toolbars/CreateToolbar/CreateToolbar";
import React from "react";
import CameraForm from "../../components/forms/CameraForm";

const CameraCreate = () => (
    <Create actions={<CreateActions />} redirect="show" >
        <SimpleForm  toolbar={<CreateToolbar />}>
            <CameraForm />
        </SimpleForm>
    </Create>
);

export default CameraCreate