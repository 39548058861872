import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from "react-admin";
import { userProvider } from "./userProvider";
import { courtProvider } from "./courtProvider";
import { userRolesReferenceProvider } from "./userRolesReferenceProvider";
import { livestreamProvider } from "./livestreamProvider";
import { clubProvider } from "./clubProvider";
import RESOURCES from "../utils/constants/resources";
import { countryReferenceProvider } from "./countryReferenceProvider";
import { clubScopesReferenceProvider } from "./clubScopesReferenceProvider";
import {
  LIVESTREAM,
  ASSIGN_CAMERA,
  PUT_LIVESTREAM_SETTINGS,
  POST_LIVESTREAM_SETTINGS
} from "../utils/constants/CUSTOM_ACTIONS";
import {cameraProvider} from "./cameraProvider";
import {livestreamSettingsProvider} from "./livestreamSettingsProvider";

const dataProviders = [
  { dataProvider: userProvider, resources: [RESOURCES.users] },
  { dataProvider: clubProvider, resources: [RESOURCES.clubs] },
  { dataProvider: courtProvider, resources: [RESOURCES.courts] },
  { dataProvider: cameraProvider, resources: [RESOURCES.cameras] },
  { dataProvider: livestreamProvider, resources: [RESOURCES.livestreams] },
  { dataProvider: livestreamSettingsProvider, resources: [RESOURCES.livestreamSettings] },
  {
    dataProvider: userRolesReferenceProvider,
    resources: [RESOURCES.userRoles],
  },
  {
    dataProvider: clubScopesReferenceProvider,
    resources: [RESOURCES.clubScopes],
  },
  { dataProvider: countryReferenceProvider, resources: [RESOURCES.countries] },
];

const dataProviderIndex = (type, resource, params) => {
  const dataProviderMapping = dataProviders.find((dp) =>
    dp.resources.includes(resource),
  );

  const mappingType = {
    [GET_LIST]: "getList",
    [GET_ONE]: "getOne",
    [GET_MANY]: "getMany",
    [GET_MANY_REFERENCE]: "getManyReference",
    [CREATE]: "create",
    [UPDATE]: "update",
    [UPDATE_MANY]: "updateMany",
    [DELETE]: "delete",
    [LIVESTREAM]: "livestream",
    [ASSIGN_CAMERA]: "assignCamera",
  };

  if (type === LIVESTREAM && dataProviderMapping?.dataProvider.livestream) {
    return dataProviderMapping.dataProvider.livestream(resource, params);
  }
  if (type === ASSIGN_CAMERA && dataProviderMapping?.dataProvider.assign) {
    return dataProviderMapping.dataProvider.assign(resource, params);
  }

  return dataProviderMapping.dataProvider[mappingType[type]](resource, params);
};

export default dataProviderIndex;
