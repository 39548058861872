import {AutocompleteInput, BooleanInput, ReferenceInput, TextInput, required} from "react-admin";
import React from "react";
import CAMERA_FIELDS from "../../utils/constants/labels/CameraFields";
import CAMERA_TYPES from "../../utils/constants/CameraTypes";
import {useLocation} from "react-router-dom";
import LIVESTREAM_SETTINGS_FIELDS from "../../utils/constants/labels/LivestreamSettingsFields";

const CameraForm = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const courtId = searchParams.get('courtId');

    return (
        <div>
            <BooleanInput
                source={LIVESTREAM_SETTINGS_FIELDS.streamingEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.streamingEnabled.label}
            />
            <BooleanInput
                source={LIVESTREAM_SETTINGS_FIELDS.fullEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.fullEnabled.label}
            />
            <BooleanInput
                source={LIVESTREAM_SETTINGS_FIELDS.liteEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.liteEnabled.label}
            />
            <BooleanInput
                source={LIVESTREAM_SETTINGS_FIELDS.staticEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.staticEnabled.label}
            />
            <BooleanInput
                source={LIVESTREAM_SETTINGS_FIELDS.commentaryEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.commentaryEnabled.label}
            />
            <ReferenceInput
                source={LIVESTREAM_SETTINGS_FIELDS.courtId.source}
                reference={LIVESTREAM_SETTINGS_FIELDS.courtId.reference}
                valid
            >
                <AutocompleteInput
                    label={LIVESTREAM_SETTINGS_FIELDS.courtId.label}
                    fullWidth
                    optionText={(court) => (
                        <span>
                                  <b>{court.name}</b>
                                </span>
                    )}
                    inputText={(court) => (court.name)}
                    defaultValue={courtId ?? null}
                    validate={[required()]}
                />
            </ReferenceInput>
        </div>
    )
}

export default CameraForm