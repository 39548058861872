const LIVESTREAM_SETTINGS_FIELDS = {
    id: {
        source: "id",
        label: "Id",
    },
    streamingEnabled: {
        source: "streamingEnabled",
        label: "Streaming Enabled",
    },
    fullEnabled: {
        source: "fullEnabled",
        label: "Full Enabled",
    },
    liteEnabled: {
        source: "liteEnabled",
        label: "Lite Enabled",
    },
    staticEnabled: {
        source: "staticEnabled",
        label: "Static Enabled",
    },
    commentaryEnabled: {
        source: "commentaryEnabled",
        label: "Commentary Enabled",
    },
    courtId: {
        source: "courtId",
        label: "Court Id",
        reference: "courts"
    },
}
export default LIVESTREAM_SETTINGS_FIELDS