import {Create, SimpleForm} from "react-admin";
import CreateActions from "../../components/actionButtons/CreateActions";
import CreateToolbar from "../../components/toolbars/CreateToolbar/CreateToolbar";
import React from "react";
import LivestreamSettingsForm from "../../components/forms/LivestreamSettingsForm";

const LivestreamSettingsCreate = () => (
    <Create actions={<CreateActions />} redirect="show" >
        <SimpleForm  toolbar={<CreateToolbar />}>
            <LivestreamSettingsForm />
        </SimpleForm>
    </Create>
);

export default LivestreamSettingsCreate