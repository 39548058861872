import React from 'react';
import {Edit, SimpleForm} from "react-admin";
import CourtForm from "../../components/forms/CourtForm";
import EditActions from "../../components/actionButtons/EditActions";
import CreateToolbar from "../../components/toolbars/CreateToolbar/CreateToolbar";
import EditToolbar from "../../components/toolbars/EditToolbar/EditToolbar";

const CourtEdit = () => (
  <Edit actions={<EditActions/>}  >
    <SimpleForm  toolbar={<EditToolbar/>} component={CourtForm}/>
  </Edit>
);

export default CourtEdit;
