import React, { useEffect, useState} from "react";
import {
    BooleanField,
    Show,
    TextField,
    Labeled,
    ShowButton, useRecordContext, useDataProvider, useRedirect, AutocompleteInput, ReferenceInput
} from "react-admin";
import ShowActions from "../../components/actionButtons/ShowActions";
import {Autocomplete, Button, Card, Grid, Stack, Typography, TextField as MuiTextField} from "@mui/material";
import COURT_FIELDS from "../../utils/constants/labels/courtFields";
import ContainerPadding from "../../components/layout/containerPadding/ContainerPadding";
import { useParams } from 'react-router-dom';
import { useGetList } from 'react-admin';
import RecordReader from "../../components/helpers/RecordReader";
import dataProviderIndex from "../../providers";
import {ASSIGN_CAMERA} from "../../utils/constants/CUSTOM_ACTIONS";
import RESOURCES from "../../utils/constants/resources";

const CourtShow = () => {
    const redirect = useRedirect();
    const {id: courtId} = useParams();
    const [hasStreamingSettings, setHasStreamingSettings] = useState(true)
    const [isAssignCameraOpen, setIsAssignCameraOpen] = useState(false)

    const { data: courtCameras, isLoading } = useGetList('cameras', {
        filter: { courtId }
    },{retry: false});
    const { data: cameras, isLoadingAll } = useGetList('cameras', );

    return (<Show actions={<ShowActions/>}>
        <RecordReader stateSetter={setHasStreamingSettings} valueToCheck={'livestreamSettings'}/>
        <Card>
            <Stack>
                <ContainerPadding padding="1% 2% 1% 2%">
                    <Grid container flexDirection="column">
                        <Labeled>
                            <TextField
                                source={COURT_FIELDS.name.source}
                                label={COURT_FIELDS.name.label}
                                emptyText="-"
                            />
                        </Labeled>
                        <Labeled>
                            <TextField
                                source={COURT_FIELDS.description.source}
                                label={COURT_FIELDS.description.label}
                                emptyText="-"
                            />
                        </Labeled>
                        <Labeled>
                            <TextField
                                source={COURT_FIELDS.courtConfigurationId.source}
                                label={COURT_FIELDS.courtConfigurationId.label}
                                emptyText="-"
                            />
                        </Labeled>

                        <Labeled>
                            <TextField
                                source={COURT_FIELDS.address.source}
                                label={COURT_FIELDS.address.label}
                                emptyText="-"
                            />
                        </Labeled>
                        <Labeled>
                            <TextField
                                source={COURT_FIELDS.publicIp.source}
                                label={COURT_FIELDS.publicIp.label}
                                emptyText="-"
                            />
                        </Labeled>
                        <Labeled>
                            <TextField
                                source={COURT_FIELDS.networkHardwareInstalled.source}
                                label={COURT_FIELDS.networkHardwareInstalled.label}
                                emptyText="-"
                            />
                        </Labeled>

                        <Labeled>
                            <BooleanField
                                source={COURT_FIELDS.livestreamEnabled.source}
                                label={COURT_FIELDS.livestreamEnabled.label}
                            />
                        </Labeled>

                        <Typography variant="h6" sx={{paddingTop: 0, paddingBottom: "10px", marginTop: 2}}>
                            Organisations
                        </Typography>
                        <OrgsList />


                        <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                            <Typography variant="h6" sx={{paddingTop: 0, paddingBottom: "10px", marginTop: 2}}>
                                Cameras
                            </Typography>
                            <Button
                                variant="contained"
                                style={{width: 100, height: 30}}
                                onClick={() => redirect(`/cameras/create?courtId=${courtId}`)}
                            >
                                Create
                            </Button>
                            <Button
                                variant="contained"
                                style={{width: 100, height: 30}}
                                onClick={() => setIsAssignCameraOpen(prevState => !prevState)}
                            >
                                {isAssignCameraOpen ? 'Close' : 'Assign'}
                            </Button>
                            {(!isLoadingAll && !isLoading)  && <CameraAssignField courtId={courtId} isOpen={isAssignCameraOpen} cameras={cameras} courtCameras={courtCameras}/>}
                        </div>
                        <CamerasList cameras={courtCameras} isLoading={isLoading} />

                        <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                            <Typography variant="h6" sx={{paddingTop: 0, paddingBottom: "10px"}}>
                                Streaming
                            </Typography>
                            {!hasStreamingSettings &&
                                <Button
                                    variant="contained"
                                    style={{width: 100, height: 30}}
                                    onClick={() => redirect(`/livestreamSettings/create?courtId=${courtId}`)}
                                >
                                    Create
                                </Button>
                            }
                        </div>
                        <StreamSettings />

                        <Labeled>
                            <BooleanField
                                source={COURT_FIELDS.livestreamSettingsCommentaryEnabled.source}
                                label={COURT_FIELDS.livestreamSettingsCommentaryEnabled.label}
                            />
                        </Labeled>
                        <Labeled>
                            <BooleanField
                                source={COURT_FIELDS.livestreamSettingsFullEnabled.source}
                                label={COURT_FIELDS.livestreamSettingsFullEnabled.label}
                            />
                        </Labeled>
                        <Labeled>
                            <BooleanField
                                source={COURT_FIELDS.livestreamSettingsLiteEnabled.source}
                                label={COURT_FIELDS.livestreamSettingsLiteEnabled.label}
                            />
                        </Labeled>
                        <Labeled>
                            <BooleanField
                                source={COURT_FIELDS.livestreamSettingsStaticEnabled.source}
                                label={COURT_FIELDS.livestreamSettingsStaticEnabled.label}
                            />
                        </Labeled>
                    </Grid>
                </ContainerPadding>
            </Stack>
        </Card>
    </Show>)
};


const CamerasList = (params) => {
    const {cameras, isLoading} = params

    if (isLoading) return null;

    return (
        <div>
            {cameras.map(camera => (
                <div>
                    <ShowButton resource="cameras" record={{id: camera.id}} label={`${camera.type} ${camera.role}`} />
                </div>
            ))}
        </div>
    );
};

const StreamSettings = () => {
    const { id: courtId } = useParams();
    const { data, isLoading } = useGetList('livestreamSettings', {
        filter: { courtId }
    },{retry: false});

    if (isLoading || !data) return null;

    return (
        <div>
            {data.map(set => (
                <div>
                    <ShowButton resource="livestreamSettings" record={{id: set.id}} label={`${set.id}`} />
                </div>
            ))}
        </div>
    );
};

const OrgsList = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadOrgs = async () => {
            if (!record?.orgIds) return;

            try {
                const results = await Promise.all(
                    record.orgIds.map(async id => {
                        const { data: club } = await dataProvider.getOne('clubs', { id });
                        return club;
                    })
                );
                setData(results);
            } catch (error) {
                console.error('Error loading clubs:', error);
            } finally {
                setLoading(false);
            }
        };

        loadOrgs();
    }, [record, dataProvider]);

    if (!record) return null;
    if (loading) return <div>Loading...</div>;

    return (
        <div>
            {data.map(club => (
                <div key={club.id}>
                    <ShowButton
                        resource="clubs"
                        record={{ id: club.id }}
                        label={club.name }
                    />
                </div>
            ))}
        </div>
    );
};

const CameraAssignField = (params) => {
    const {courtId, isOpen, cameras, courtCameras} = params
    const [selectedCameras,setSelectedCameras] = useState(courtCameras)

    if (!isOpen) return null;

    const handleSelectionChange = (event, value) => {
        const selectedIds = value.map((option) => option.id);
        setSelectedCameras(selectedIds);
    };
    const onSaveCamerasClick = async (assignedCameras) => {
        await dataProviderIndex(ASSIGN_CAMERA, RESOURCES.courts, {id: courtId, cams: assignedCameras});
        window.location.reload();
    }

    return (
        <>
            <Autocomplete
                id="cameras-auto"
                options={cameras}
                multiple
                getOptionLabel={(option) => `${option.serialNumber}, ${option.type}, ${option.role}`}
                style={{ width: 500 }}
                renderInput={(params) => <MuiTextField {...params} label="Cameras" variant="outlined" />}
                getOptionSelected={(option, value) => option.id === value.id}
                defaultValue={selectedCameras}
                onChange={handleSelectionChange}
            />
            <Button
                variant="contained"
                style={{width: 50, height: 30}}
                onClick={() => onSaveCamerasClick(selectedCameras)}
                disabled={!selectedCameras.length}
            >
                Save
            </Button>
        </>
    )
}

export default CourtShow;
