import EditActions from "../../components/actionButtons/EditActions";
import {Edit, SimpleForm} from "react-admin";
import EditToolbar from "../../components/toolbars/EditToolbar/EditToolbar";
import React from "react";
import LivestreamSettingsForm from "../../components/forms/LivestreamSettingsForm";

const LivestreamSettingsEdit = () => (
    <Edit actions={<EditActions />} redirect={false}>
        <SimpleForm toolbar={<EditToolbar />}>
            <LivestreamSettingsForm edit />
        </SimpleForm>
    </Edit>
);

export default LivestreamSettingsEdit