import {AutocompleteInput, BooleanInput, ReferenceInput, TextInput, required} from "react-admin";
import React from "react";
import CAMERA_FIELDS from "../../utils/constants/labels/CameraFields";
import CAMERA_TYPES from "../../utils/constants/CameraTypes";
import {useLocation} from "react-router-dom";
import { useFormContext } from 'react-hook-form';

const CameraForm = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const courtId = searchParams.get('courtId');
    const {setValue} = useFormContext();

    return (
        <div>
            <TextInput
                source={CAMERA_FIELDS.serialNumber.source}
                label={CAMERA_FIELDS.serialNumber.label}
                fullWidth
                required
            />
            <TextInput
                source={CAMERA_FIELDS.modelDescription.source}
                label={CAMERA_FIELDS.modelDescription.label}
                fullWidth
                required
            />
            <TextInput
                source={CAMERA_FIELDS.username.source}
                label={CAMERA_FIELDS.username.label}
                fullWidth
                required
            />
                <TextInput
                source={CAMERA_FIELDS.password.source}
                label={CAMERA_FIELDS.password.label}
                fullWidth
                required
            />
            <TextInput
                source={CAMERA_FIELDS.rtspPort.source}
                label={CAMERA_FIELDS.rtspPort.label}
                fullWidth
            />
            <TextInput
                source={CAMERA_FIELDS.apiPort.source}
                label={CAMERA_FIELDS.apiPort.label}
                fullWidth
            />
                <BooleanInput
                    source={CAMERA_FIELDS.hasLineIn.source}
                    label={CAMERA_FIELDS.hasLineIn.label}
                />
                <BooleanInput
                    source={CAMERA_FIELDS.hasMic.source}
                    label={CAMERA_FIELDS.hasMic.label}
                />
                <AutocompleteInput
                    source={CAMERA_FIELDS.type.source}
                    choices={CAMERA_TYPES}
                    label={CAMERA_FIELDS.type.label}
                    fullWidth validate={required()}
                    onChange={(e, value) => setValue(CAMERA_FIELDS.role.source, value.id === 'Livestream' ? 'Main' : null)}
                />
                <TextInput
                    source={CAMERA_FIELDS.role.source}
                    label={CAMERA_FIELDS.role.label}
                    defaultValue={null}
                    fullWidth
                    disabled
                />
                <ReferenceInput
                    source={CAMERA_FIELDS.courtId.source}
                    reference={CAMERA_FIELDS.courtId.reference}
                >
                    <AutocompleteInput
                        label={CAMERA_FIELDS.courtId.label}
                        fullWidth
                        optionText={(court) => (
                            <span>
                                  <b>{court.name}</b>
                                </span>
                        )}
                        inputText={(court) => (court.name)}
                        defaultValue={courtId ?? null}
                    />
                </ReferenceInput>
        </div>
    )
}

export default CameraForm