import ClubListFiltersSearch from "../ClubList/ClubListFiltersSearch";
import ListActions from "../../../components/actionButtons/ListActions";
import React from "react";
import {BooleanField, Datagrid, List, TextField} from "react-admin";
import LIVESTREAM_SETTINGS_FIELDS from "../../../utils/constants/labels/LivestreamSettingsFields";

const LivestreamSettingsList = () => (
    <List
        filters={ClubListFiltersSearch()}
        pagination={false}
        actions={<ListActions />}
    >
        <Datagrid rowClick="show">
            <TextField
                source={LIVESTREAM_SETTINGS_FIELDS.id.source}
                label={LIVESTREAM_SETTINGS_FIELDS.id.label}
                emptyText="-"
            />
            <BooleanField
                source={LIVESTREAM_SETTINGS_FIELDS.streamingEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.streamingEnabled.label}
            />
            <BooleanField
                source={LIVESTREAM_SETTINGS_FIELDS.fullEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.fullEnabled.label}
            />
            <BooleanField
                source={LIVESTREAM_SETTINGS_FIELDS.liteEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.liteEnabled.label}
            />
            <BooleanField
                source={LIVESTREAM_SETTINGS_FIELDS.staticEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.staticEnabled.label}
            />
            <BooleanField
                source={LIVESTREAM_SETTINGS_FIELDS.commentaryEnabled.source}
                label={LIVESTREAM_SETTINGS_FIELDS.commentaryEnabled.label}
            />
        </Datagrid>
    </List>
)

export default LivestreamSettingsList;